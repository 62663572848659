// src/components/PRGrid.js

import React from 'react';
import PRTable from './PRTable';
import '../styles/PRGrid.css'; // We'll create this CSS file next

const PRGrid = ({ friends }) => {
  return (
    <div className="prgrid">
      {friends.map((friend, index) => (
        <div key={index} className="prgrid-item">
          <PRTable friend={friend} />
        </div>
      ))}
    </div>
  );
};

export default PRGrid;
