// src/components/PRTable.js

import React from 'react';

const PRTable = ({ friend }) => {
  return (
    <div className="pr-table">
      <h2>{friend.name}</h2>
      <table>
        <thead>
          <tr>
            <th>Lift</th>
            <th>Starting PR</th>
            <th>Current PR</th>
            <th>Difference</th>
          </tr>
        </thead>
        <tbody>
          {friend.prs.map((pr, index) => (
            <tr key={index}>
              <td>{pr.lift}</td>
              <td>{pr.startingPR} kg</td>
              <td>{pr.currentPR} kg</td>
              <td>{pr.currentPR - pr.startingPR}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PRTable;
