// src/App.js

import React, { useState } from 'react';
import PRGrid from './components/PRGrid';
import './App.css';

function App() {
  const [friends] = useState([
    {
      name: 'Philip',
      prs: [
        { lift: 'Squat', startingPR: 140, currentPR: 143 },
        { lift: 'Bench', startingPR: 100, currentPR: 100 },
        { lift: 'OHP', startingPR: 64, currentPR: 66 },
        { lift: 'Deadlift', startingPR: 149, currentPR: 154 },
        { lift: 'Chins', startingPR: 16, currentPR: 17 },
      ],
    },
    {
      name: 'Adam',
      prs: [
        { lift: 'Squat', startingPR: 130, currentPR: 130 },
        { lift: 'Bench', startingPR: 45, currentPR: 45 },
        { lift: 'OHP', startingPR: 45, currentPR: 45 },
        { lift: 'Deadlift', startingPR: 120, currentPR: 120 },
        { lift: 'Chins', startingPR: 0, currentPR: 0 },
      ],
    },
    {
      name: 'Erik',
      prs: [
        { lift: 'Squat', startingPR: 88, currentPR: 99 },
        { lift: 'Bench', startingPR: 65, currentPR: 69 },
        { lift: 'OHP', startingPR: 55, currentPR: 57 },
        { lift: 'Deadlift', startingPR: 110, currentPR: 117 },
        { lift: 'Chins', startingPR: 4, currentPR: 4 },
      ],
    },
    {
      name: 'Lovisa',
      prs: [
        { lift: 'Squat', startingPR: 60, currentPR: 82 },
        { lift: 'Bench', startingPR: 42, currentPR: 50 },
        { lift: 'OHP', startingPR: 30, currentPR: 36 },
        { lift: 'Deadlift', startingPR: 88, currentPR: 93 },
        { lift: 'Chins', startingPR: 5, currentPR: 10 },
      ],
    },
  ]);

  return (
    <div className="App">
      <header>
        <h1>Sjukt stenhårt gym</h1>
      </header>
      <PRGrid friends={friends} />
    </div>
  );
}

export default App;
